import React from 'react'
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { navigateTo } from 'gatsby-link'

import Home from '../../../assets/img/home.jpg'

import PourquoiIrriglobemp4 from '../../../assets/video/Irriglobe_Installation_Résidentiel_FINAL_480p.mp4'
import PourquoiIrriglobewebm from '../../../assets/video/Irriglobe_Installation_Residentiel_FINAL_480p.webm'

import {
  Container,
  Col,
  Row
} from 'reactstrap'

import SEO from '../../../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Services Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 Pascal-Gagnon"
    },
    "description": "Irriblobe se positionne en tant qu'expert dans le domaine des Systèmes d'Irrigation et de l'Arrosage Automatique",
    "telephone": "514-905-6000"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          }
        ]
      }
    ]
  }
}`

class commentInstallerSystemeIrrigationIndex extends React.Component {
  render() {

    const seoData = {
      title: "Comment installer un système d'irrigation : Guide complet et illustré",
      description:
        "L'installation d'un système d'irrigation est une solution efficace pour entretenir vos espaces verts tout en optimisant la consommation d’eau. Voici un guide détaillé et professionnel pour réaliser une installation complète, étape par étape.",
      cover: Home,
      path: '/documentations/comment-installer-un-systeme-irrigation',
      schemaOrg
    }

    const allImages = this.props.data.allImageSharp
    
    const premiereEtape = allImages.edges.find(e => e.node.fluid.originalName === 'premiere-etape.png')?.node.fluid
    const dispositifAntiRefoulement = allImages.edges.find(e => e.node.fluid.originalName === 'dispositif-anti-refoulement.png')?.node.fluid
    const valve = allImages.edges.find(e => e.node.fluid.originalName === 'valve.png')?.node.fluid
    const tuyaux = allImages.edges.find(e => e.node.fluid.originalName === 'tuyaux.png')?.node.fluid
    const minuterie = allImages.edges.find(e => e.node.fluid.originalName === 'minuterie.png')?.node.fluid
    const tests = allImages.edges.find(e => e.node.fluid.originalName === 'tests.png')?.node.fluid

    let mobile = false
    try {
        mobile = window.innerWidth <= 991 ? true : false
    } catch (e) {}

    const styleBlock = {alignItems: 'center', justifyContent: 'center', display: 'flex', height: 515}

    return (
      <div>
        <SEO seoData={seoData} />
        
        <Container>
          <Row>
              <Col md={6} xs={12} style={{marginBottom: 50}}>
                <h1>Comment installer un système d’irrigation</h1>
                <p>
                L'installation d'un système d'irrigation est une solution efficace pour entretenir vos espaces verts tout en optimisant la consommation d’eau. Voici un guide détaillé et professionnel pour réaliser une installation complète, étape par étape.
                </p>
                <a class="btn-round btn btn-primary btn-lg" href="/services">Voir nos services</a>
              </Col>
              <Col md={6} xs={12}>
              <video controls style={{ width: '100%' }} autoplay>
                <track default kind="captions"
                  srcLang="fr"
                  src="" />
                <source src={PourquoiIrriglobewebm} type="video/webm" />
                <source src={PourquoiIrriglobemp4} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Planification et marquage de l’emplacement du système</h2>
              <p>
              La première étape consiste à bien préparer votre projet.
              </p>
              <ul>
                <li>
                <strong>Dessinez un plan détaillé</strong>  de votre jardin, identifiant les zones d’irrigation et les emplacements des gicleurs en fonction des besoins des plantes.
                </li>
                <li>
                  <strong>Marquez les emplacements au sol</strong> à l’aide de fanions pour les gicleurs et tracez les lignes des conduites avec de la peinture temporaire.
                </li>
                <li>
                  <strong>Vérifiez les infrastructures souterraines</strong> pour éviter d’endommager des canalisations ou câbles électriques.
                </li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={premiereEtape} alt="Première étape" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Installation du dispositif anti-refoulement</h2>
              <p>
              Un dispositif anti-refoulement est essentiel pour protéger l'eau potable contre tout retour provenant du système d’irrigation.
              </p>
              <ul>
                <li>
                Localisez et exposez la <strong>conduite principale d’eau.</strong>
                </li>
                <li>
                Coupez l’alimentation en eau et relâchez la pression des tuyaux en ouvrant les robinets.
                </li>
                <li>
                Installez un <strong>raccord en T</strong> pour créer une dérivation vers le système d’irrigation.
                </li>
                <li>Fixez le dispositif anti-refoulement selon les recommandations du fabricant.</li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={dispositifAntiRefoulement} alt="Dispositif anti-refoulement" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Pose du boîtier de valves</h2>
              <p>
              Le boîtier de valves est enterré pour un accès pratique et une apparence discrète.
              </p>
              <ul>
                <li>
                Creusez une cavité d’environ <strong>18 pouces de profondeur</strong> avec une largeur suffisante pour accueillir la boîte.
                </li>
                <li>
                Ajoutez une couche de gravier au fond pour un meilleur drainage.
                </li>
                <li>
                Placez les <strong>valves solénoïdes</strong> dans le boîtier et reliez-les à la conduite principale ainsi qu’aux tuyaux menant aux zones d’irrigation.
                </li>
                <li>Passez les fils électriques jusqu’à l’emplacement de la minuterie.</li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={valve} alt="Valve" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Assemblage et pose des tuyaux d'arrosage</h2>
              <p>
              Pour un système efficace, utilisez des tuyaux en <strong>polyéthylène noir</strong>, robustes et flexibles.
              </p>
              <ul>
                <li>
                Assemblez les tuyaux avec des <strong>raccords et bagues de serrage</strong> en acier inoxydable.
                </li>
                <li>
                Déposez les tuyaux dans les tranchées préalablement creusées, suivant les tracés au sol.
                </li>
                <li>
                Installez des raccords en <strong>T</strong> ou des coudes aux emplacements des futurs gicleurs.
                </li>
                <li>Bouchez temporairement les extrémités pour tester le système.</li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={tuyaux} alt="Tuyaux" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Mise en place de la minuterie d’irrigation</h2>
              <p>
              La minuterie automatisera votre système pour un arrosage efficace et adapté aux besoins.
              </p>
              <ul>
                <li>
                Fixez le programmateur dans un <strong>endroit accessible</strong>, comme un mur de garage ou un extérieur protégé.
                </li>
                <li>
                Connectez les <strong>fils électriques des solénoïdes</strong> aux bornes correspondantes du programmateur.
                </li>
                <li>
                Configurez les <strong>horaires d’arrosage</strong> en tenant compte des besoins spécifiques de chaque zone et des restrictions locales.
                </li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={minuterie} alt="Minuterie" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={8}>
              <h2>Test final et ajustements des gicleurs</h2>
              <p>
              Avant de finaliser l’installation :
              </p>
              <ul>
                <li>
                Vérifiez qu’il n’y a <strong>aucune fuite</strong> au niveau des raccords.
                </li>
                <li>
                Activez chaque zone depuis le programmateur pour tester le bon fonctionnement des gicleurs.
                </li>
                <li>
                Ajustez les angles et le débit des gicleurs pour une couverture optimale.
                </li>
                <li>
                Compacter la terre dans les tranchées et <strong>nettoyer la zone de travail.</strong>
                </li>
              </ul>
             
            </Col>
            <Col md={4}>
              <Img fluid={tests} alt="Tests final et ajustement des gicleurs" />
              </Col>
          </Row>
        </Container>

        <Container>
          <Row style={mobile ? {} : styleBlock}>
            <Col md={12}>
              <h2>Pourquoi choisir Irriglobe pour votre installation ?</h2>
              <ul>
                <li>
                <strong>Plus de 20 ans d’expérience</strong> dans les systèmes d’irrigation.
                </li>
                <li>
                <strong>Technologies modernes</strong> incluant des gicleurs rétractables et des solutions respectueuses de l’environnement.
                </li>
                <li>
                <strong>Service clé en main,</strong> de la conception à la mise en service pour une tranquillité d’esprit totale.
                </li>
              </ul>
              <p><a onClick={() => navigateTo('/nous-joindre')} title="Nous joindre" href="/nous-joindre"><strong>Contactez-nous dès aujourd’hui</strong></a> pour une installation professionnelle et durable. Faites confiance à Irriglobe pour un arrosage efficace et une pelouse toujours impeccable !</p>
            </Col>
          </Row>
        </Container>

      </div>
    )
  }
}

export default commentInstallerSystemeIrrigationIndex

export const commentInstallerSystemeIrrigationPageQuery = graphql`
  query commentInstallerSystemeIrrigationQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "premiere-etape.png",
      "dispositif-anti-refoulement.png",
      "valve.png",
      "tuyaux.png",
      "minuterie.png",
      "tests.png"
    ]}}}) {
      edges {
        node {
          fluid {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed {
            originalName
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
